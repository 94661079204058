.chip {
    @apply px-[12px] py-[6px] border-[1px] border-gray-300 rounded bg-white;
}

.chip-blue {
    @apply bg-primary-50 text-primary-600 border-primary-300;
}
.chip-gray {
    @apply bg-gray-100 text-hint border-gray-100
}
.chip-small {
    @apply px-tnano py-[2px];
}

.chip-rounded{
    @apply rounded-full
}
.chip-gradient {
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.82),
            rgba(255, 255, 255, 0.82)),
    linear-gradient(239.51deg, #1ed4c4 10.82%, #238df2 88.29%);
}
.chip-yellow{
    @apply text-warning-900 bg-warning-50 border-0;
}
html,
body,
#__next {
  height: 100%;
}

.text-hint {
  @apply text-gray-600;
}

.text-primary-gray {
  @apply text-gray-900;
}

.text-secondary-gray {
  @apply text-gray-700;
}

/* styling for rhythm components */
/* .label {
  @apply p-0 text-base font-semibold  mb-tnano;
} */

.card {
  @apply rounded-[4px] border border-gray-100 shadow-none;
}

.chip {
  @apply rounded border-[1px] border-gray-300 bg-white px-tnano py-[2px];
}

.chip-blue {
  @apply rounded-[32px] border-none bg-primary-50 px-tnano py-[2px] text-primary-600;
}

.chip-gradient {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.82),
      rgba(255, 255, 255, 0.82)
    ),
    linear-gradient(239.51deg, #1ed4c4 10.82%, #238df2 88.29%);
}

.card-hover {
  @apply border border-[#e9e9e9] shadow-none;
}
.card-hover:hover {
  /* filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.04)); */
  /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04); */
  @apply cursor-pointer rounded-lg border border-[#fff];
}

/* styling for rhythm components ends */

/* generic styles used throughout the project */
.custom-infinite-scroll .infinite-scroll-component__outerdiv {
  width: 100%;
}

.t-text-dark {
  color: black;
}
.text-light {
  color: grey;
}

.client-login-bg {
  background-image: linear-gradient(
    to left top,
    #22e9d7,
    #00dce9,
    #00cdf8,
    #00bcff,
    #00a9fd,
    #4da8fb,
    #6da6f9,
    #84a5f5,
    #acb9f7,
    #cccff9,
    #e8e6fc,
    #ffffff
  );
}

/* define all custom classes */

.list-gradient {
  background: linear-gradient(270.35deg, #defffc 0%, #e1effd 100%);
}

.candidate-details-linear-gradient {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.96),
      rgba(255, 255, 255, 0.96)
    ),
    #000000;
}

.dot::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 15px;
  left: -31px;
  background-color: #179f93;
}
@media only screen and (max-width: 1024px) {
  .dot::before {
    opacity: 0;
  }
}

.zeal-assessed-chip {
  @apply rounded-[44px] bg-[#fcf4dc] px-4 py-[7px] text-sm font-semibold text-[#715509];
}
.yellow-gradient-1 {
  background: linear-gradient(270.35deg, #a97f0e 0%, #e1effd 100%);
}

.skeleton-bg {
  @apply bg-secondary-200;
}
.navigation-bg {
  @apply bg-secondary-100;
}
.icon-light {
  @apply text-secondary-300;
}

.icon-sm {
  @apply h-[16px] w-[16px];
}

.icon-md {
  @apply h-[20px] w-[20px]; /* This is default size for icon. But in some places, like icon inside the search box, we need to specify the size.*/
}

.icon-lg {
  @apply h-[24px] w-[24px];
}

.iconify {
  @apply h-[20px] w-[20px];
}

.avatar-item-open-header {
  @apply p-7;
}
.accordian-item-icon {
  @apply text-primary-500;
}

.accordian-item-open-header {
  @apply p-5;
}
.accordian-item {
  @apply border;
}
.zelevate-container {
  @apply px-10 lg:px-20;
}

/* LANDING PAGE */
.text-blue-highlight {
  color: #51a6f3;
}
.text-gradient-1 {
  background: linear-gradient(96.68deg, #27ddbc 1.46%, #006acf 100.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box-shadow-1 {
  -webkit-box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
}
.profile-bg-1 {
  background: linear-gradient(270.35deg, #defffc 0%, #e1effd 100%);
}

.footer-gradient-1 {
  background: linear-gradient(96.68deg, #27ddbc 1.46%, #006acf 100.98%);
  opacity: 0.7;
  filter: blur(126px);
}
.side-gradient-1 {
  background: linear-gradient(
      96.68deg,
      rgba(39, 221, 188, 0.08) 1.46%,
      rgba(0, 106, 207, 0.08) 100.98%
    ),
    #ffffff;
}
.bg-greadient-1 {
  background: linear-gradient(
    270.35deg,
    rgba(222, 255, 252, 0.1) 0%,
    rgba(225, 239, 253, 0.1) 100%
  );
}

.btn-bg {
  background: linear-gradient(278.25deg, #1ed4c4 -3.8%, #238df2 31.92%);
  border-width: 0px;
  /* border-radius: 4px; */
}

.section-bg {
  background: linear-gradient(
      96.68deg,
      rgba(39, 221, 188, 0.08) 1.46%,
      rgba(0, 106, 207, 0.08) 100.98%
    ),
    #ffffff;
  border-radius: 4px;
}
.section-bg-2 {
  background: linear-gradient(96.68deg, #27ddbc 1.46%, #006acf 100.98%);
  /* opacity: 0.3; */
  filter: blur(126px);

  border-radius: 4px;
}

/* //hiring steps animation */

.arrow {
  position: absolute;
  bottom: -80px;
  right: -20px;
  transform: rotateY(17deg) rotate(43deg);
  z-index: 20;
}

.strikethrough {
  position: relative;
  display: inline-block;
  color: rgb(118, 115, 115);
}

.strikethrough::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.5px;
  color: rgb(118, 115, 115);
  top: 50%;
  background-color: rgb(135, 131, 131);
  transform: scaleX(0);
  transform-origin: left;
  animation: strike 0.5s ease-in 0s forwards;
}
.isHighlighted {
  outline: 3px solid #b2eaec;
  font-weight: bolder;
  background: #dffcfd;
}

@keyframes strike {
  to {
    transform: scaleX(1);
  }
}

.companies-slider:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* z-index: 20; */
  width: 100%;
  height: 100%;
  /* opacity: 0.7; */
  background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    ),
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
  /* background: linear-gradient(90deg, #fcfcfc 0%, rgba(255, 255, 255, 0) 100%); */
}

/* linear-gradient(0deg, #000000, #000000); */
/* background: -webkit-linear-gradient(#eee, #333); */
.zeal-text-gradient {
  background: linear-gradient(96.68deg, #27ddbc 1.46%, #006acf 100.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide-y-scroll {
  overflow-y: hidden !important;
}
.react-select__menu-portal {
  z-index: 999 !important;
}

.theme-bg-gray {
  background-color: rgba(252, 252, 252, 1);
}

/* scheduler component */
.scheduler {
  width: 100%;
  max-width: none;
}

.scheduler-day-container + div {
  @apply px-0;
}

.tooltip {
  z-index: 1000;
}

.drawer {
  z-index: 50;
  /* @apply z-[50px]; */
}

.drawer-backdrop-open {
  background: rgba(56, 64, 75, 0.8);
  backdrop-filter: blur(8px);
}

.tab-item-underline {
  @apply px-tsm py-tnano;
}
.tab {
  @apply gap-0;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.divider {
  @apply border-gray-100;
}

.tab {
  @apply gap-0;
}
.tab-item {
  @apply px-tsm py-tnano;
}

input[type="checkbox"].form-control {
  @apply rounded-sm;
}

/* table styles */

.table {
  @apply rounded border border-gray-100;
}

.table-body-td {
  @apply border-b border-gray-100 bg-white;
}
.table-head {
  @apply background-blue border-b border-gray-100;
}

.pagination-label-header {
  @apply m-0 p-0 text-[14px] font-normal leading-[20px] text-[#7B7F87]; /* style same as html p tag specified in tailwind.config.js */
}

.pagination-default {
  @apply px-0;
}
.pagination-header {
  @apply px-0;
}

.drawer {
  @apply z-10;
}
/* .helper-text{
  @apply mt-0;
} */

.input-group-datepicker .input-group svg {
  @apply hidden;
}

.input-group-datepicker .input-group {
  @apply flex-1 border-none;
}

.required-asterisk::after {
  content: " *";
  color: red;
}

/* stepper */
.stepper {
  @apply grid grid-cols-3;
}
.stepper-step-label {
  @apply text-hint text-xs;
}

.stepper-step-ring-active {
  @apply border-none;
}
.stepper-step-checkpoint {
  @apply text-xs;
}
.stepper-step-checkpoint-active {
  @apply text-white;
}
.stepper-step-checkpoint-done-withouterror {
  @apply text-white;
}
.flatpickr-calendar {
  pointer-events: auto;
}
#portal-for-rh-dialog div {
  z-index: 11;
}

.RhPopoverToggle {
  @apply flex items-center justify-center gap-tpico whitespace-nowrap;
}

.RhPopoverMenu {
  @apply relative z-10 w-[180px] rounded bg-white py-tnano shadow-[0_4px_8px_0px_rgba(0,0,0,0.24)];
}

.RhPopoverMenuItem {
  @apply flex w-full items-center gap-tnano px-tsm py-tnano hover:bg-primary-50;
}

.RhPopoverMenuItemDanger {
  @apply flex w-full items-center gap-tnano px-tsm py-tnano text-red-600 hover:bg-red-50;
}

.RhPopoverMenuItemActive {
  @apply bg-blue-100;
}

.rich-text-editor-display ul {
  @apply list-disc pl-tm2;
}

.rich-text-editor-display ol {
  @apply list-decimal pl-tlg;
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";

@import "@rhythm-ui/react/lib/core.css";
@import "@rhythm-ui/react/lib/themes/default.css";
@import "react-calendar/dist/Calendar.css";
@import "./calendar.css";

@import "./theme.css";
@import "./color.css";
@import "./button.css";

@import "tailwindcss/utilities";
@import "./chip.css";

@layer utilities {
  .text-hint {
    @apply text-gray-600;
  }
}

.button {
  @apply m-0 text-[16px] px-tsm py-tnano leading-[24px] text-center;
}

.button-sm {
  @apply m-0 text-[14px] px-tmini py-tpico leading-[24px] text-center;
}

.button-primary {
  @apply bg-primary-600 hover:bg-primary-800 border-none;
}

.button-primary-outline:hover {
  @apply bg-primary-50;
}

.button[disabled] {
  @apply text-gray-700;
}

.button-primary[disabled] {
  @apply bg-gray-100;
}

.button-primary-outline[disabled] {
 @apply border-gray-200;
} 

.button.button-tertiary {
  @apply bg-white text-gray-700 hover:text-primary-600 hover:bg-blue-50;
}

.button.button-tertiary:disabled,
button.button-tertiary-blue:disabled,
.button.button-tertiary-danger:disabled {
  @apply bg-gray-100 text-gray-700;
}
.button.button-tertiary-blue {
  @apply bg-white text-primary-600 hover:text-primary-600 hover:bg-blue-50;
}

.button.button-tertiary-danger {
  @apply bg-white text-danger-600 hover:text-danger-600 hover:bg-danger-50;
}

.button:has(svg):not(#loader-button) {
  @apply p-[8px];
}

.button .iconify {
  @apply h-[20px] w-[20px];
}

.button.only-icon-button {
  @apply !p-[10px];
}

.button.only-icon-button svg {
  @apply p-0;
}
